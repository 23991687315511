.App {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

* {
  font-family: 'grotesque-mono';
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: transparent;
}

.sketch {
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  background-color: rgb(230, 230, 230);
  /*filter: blur(0.2px);*/
}

.sketch .canvas {
  height: 100%;
  width: 100%;
  mask-repeat: no-repeat;
  mask-size: 100vw 600vh;
  mask-type: luminance;
  mask-position: 0 -400vh;
  top: 0;
  position: absolute;
  /*filter: url(#sharpy);*/
}

/*
.sketch .canvas canvas {
  filter: blur(1px) drop-shadow(0px 0px 0.5px black) contrast(1);
}
*/

.home {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0 min(8vw, 20vh);
  gap: max(4.5rem, 10vh);
  font-size: max(21px, 3.3vh);
  width: calc(100vw - 2 * min(8vw, 20vh));
  width: -webkit-fill-available;
  width: -moz-available;
  pointer-events: none;
}

.home .reel {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  pointer-events: none;
}

.home.video-open .reel {
  opacity: 1;
}

.home .logo {
  width: 9.3vh;
  height: 9.3vh;
  padding: 1.8vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6vh;
  transition: all 1s cubic-bezier(0.65, 0, 0.35, 1);
  overflow: hidden;
  transform: translate(0%);
}

.home .logo>div {
  width: 9.3vh;
  height: 9.3vh;
  padding: 1.8vh;
  transition: all 1s cubic-bezier(0.65, 0, 0.35, 1);
  transform: translate(0%);
  background: #e6e6e6;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: absolute;
}

.home .logo.loading>div,
.home.video-open .logo>div {
  width: 0;
  height: 0;
  padding: 0;
}

.home .logo img {
  width: 9.3vh;
  height: 9.3vh;
}

.home .content {
  display: flex;
  flex-direction: column;
  gap: max(2rem, 4vh);
  color: #fff;
  position: relative;
  pointer-events: all;
}

.home.video-open .content {
  pointer-events: none;
}

.home .content>div {
  cursor: pointer;
  /* transition: all 1s cubic-bezier(0.65, 0, 0.35, 1); */
  transition: all 900ms cubic-bezier(0.77, 0, 0.175, 1);
  /* easeInOutQuart */
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  /* easeInOutQuart */
  position: relative;
}

.home .content>div>div {
  background-color: #000;
  padding: 0.3em 0;

  overflow: hidden;
  pointer-events: none;
  position: relative;
  transition: all 900ms cubic-bezier(0.77, 0, 0.175, 1);
  /* easeInOutQuart */
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  /* easeInOutQuart */
  position: absolute;
  right: 0;
  left: 0;
}

.home .content>div.loading>div {
  right: 0;
}

.home .content>div>div>div {
  width: max-content;
  margin: 0 0.6em;
}

.home .content>div>div>div span {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  line-height: 1em;
}

.home .content>div>div>.logos {
  position: absolute;
  top: 0;
  left: max(3rem, 6vh);
  height: calc(2.4em + 1rem);
  display: flex;
  align-items: center;
  overflow: hidden;
}

.home .content .logos img {
  position: absolute;
  height: calc(100% - 0.6em);
  object-fit: contain;
  object-position: left;
  margin: 0 0.6em;
}

.home .content .contact-btn-container {
  display: flex;
  justify-content: space-between;
  margin-top: 7vh;
  pointer-events: none;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-content: center;
  height: 2em;
}

.home .content .contact-btn-container.close-video,
.home .content .contact-btn-container.close-contact {
  position: absolute;
  right: 0;
  bottom: 70vh;
}

.home .content .contact-btn-container.close-video {
  right: 2em;
}

.home .content .contact-btn-container.hidden .contact-btn {
  pointer-events: none;
}

.home .content .btn-wrap {
  background: none;
  position: relative;
  width: 7em;
  height: 2em;
  display: flex;
  justify-content: center;
  position: relative;
  transition: all 150ms cubic-bezier(0.165, 0.84, 0.44, 1) !important;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1) !important;
}

.home .content .btn-hover-wrap-container {
  width: 7em;
  margin: 0;
  display: flex;
  /* transition: all 1s cubic-bezier(0.65, 0, 0.35, 1); */
  transition: all 900ms cubic-bezier(0.77, 0, 0.175, 1);
  /* easeInOutQuart */
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  /* easeInOutQuart */
}

.home .content .btn-wrap.projects .btn-hover-wrap-container {
  width: 6em;
}

.home .content .btn-hover-wrap-container.loading,
.home .content .btn-wrap.projects .btn-hover-wrap-container.loading,
.home .content .btn-wrap.video .btn-hover-wrap-container.loading,
.home .content .btn-wrap.close-contact .btn-hover-wrap-container.loading {
  width: 0;
}

.home .content .btn-hover-wrap {
  width: 7em;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  justify-self: flex-start;
  overflow: hidden;
  position: relative;
  /* transition: all 1s cubic-bezier(0.65, 0, 0.35, 1); */
  transition: all 900ms cubic-bezier(0.77, 0, 0.175, 1);
  /* easeInOutQuart */
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  /* easeInOutQuart */
  transform: skewX(-15deg);
}

.home .content .btn-wrap.projects .btn-hover-wrap {
  width: 6em;
}

.home .content .btn-wrap:hover .btn-hover-wrap {
  width: 0;
}

.home .content .btn-wrap.projects {
  width: 6em;
}

.home .content .contact-btn {
  pointer-events: all;
  padding: 0;
  background: none;
  color: #000;
  width: fit-content;
  /* transition: all 1s cubic-bezier(0.65, 0, 0.35, 1); */
  transition: all 900ms cubic-bezier(0.77, 0, 0.175, 1), color 0s, background 0s;
  /* easeInOutQuart */
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1), color 0s,
    background 0s;
  /* easeInOutQuart */
  transition-delay: 100ms;
  width: 7em;
  height: 1.6em;
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  margin: 0;
  position: absolute;
  transform: translateZ(0);
}

.home .content .contact-btn.back {
  color: #fff;
  transform: skewX(-15deg);
}

.contact-btn-container.close-video .contact-btn,
.contact-btn-container.close-contact .contact-btn {
  color: #fff;
}

.home .content .contact-btn.projects {
  color: #fff;
  width: 6em;
}

.home .content .contact-btn.projects.back,
.home .content .contact-btn.video.back,
.home .content .contact-btn.close-contact.back {
  color: #000;
}

.home .content .contact-btn:before {
  content: '';
  background: #e6e6e6;
  /* transition: all 1s cubic-bezier(0.65, 0, 0.35, 1); */
  transition: all 900ms cubic-bezier(0.77, 0, 0.175, 1);
  /* easeInOutQuart */
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  /* easeInOutQuart */
  transition-delay: 100ms;
  width: 90%;
  height: 100%;
  position: absolute;
  display: block;
  border-radius: 0.4vh;
}

.home .content .contact-btn.back:before {
  background: #000;
  width: calc(90% - 2px);
  height: calc(100% - 2px);
}

.contact-btn-container.close-video .contact-btn:before,
.contact-btn-container.close-contact .contact-btn:before {
  background: #000;
}

.home .content .contact-btn.projects:before {
  background: #000;
}

.home .content .contact-btn.projects.back:before,
.home .content .contact-btn.video.back:before,
.home .content .contact-btn.close-contact.back:before {
  background: #e6e6e6;
}

.home .content>div.loading {
  transform: translateX(50%);
}

.home .content>div.loading>div {
  width: 0 !important;
  padding: 0.3em 0;
}

.home .content>div.hidden>div:not(.btn-wrap) {
  width: 0 !important;
  padding: 0.3em 0;
}

.home .content .contact-btn.loading {
  width: 0;
  height: 0;
  /*transform: translateX(-3.6em);*/
}

.home .content .contact-btn>div {
  background: none;
  width: 90%;
  height: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  /* transition: all 1s cubic-bezier(0.65, 0, 0.35, 1); */
  transition: all 900ms cubic-bezier(0.77, 0, 0.175, 1);
  /* easeInOutQuart */
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  /* easeInOutQuart */
  position: relative;
  overflow: hidden;
  justify-content: center;
}

.home .content .btn-wrap .contact-btn>div>div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* transition: all 1s cubic-bezier(0.65, 0, 0.35, 1); */
  transition: all 900ms cubic-bezier(0.77, 0, 0.175, 1);
  /* easeInOutQuart */
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  /* easeInOutQuart */
  transform: skewX(15deg);
}

.home .content .btn-wrap:hover .contact-btn>div>div {
  left: -1em;
}

.home .content .btn-wrap .contact-btn.back>div {
  width: 90%;
  justify-content: center;
}

.home .content .btn-wrap .contact-btn.back>div>div {
  left: -2em;
}

.home .content .btn-wrap:hover .contact-btn.back>div>div {
  left: 0;
}

.error_form {
  flex-direction: column;
  font-size: 1.8vh !important;
  display: none !important;
}

.error_form.active {
  display: flex !important;
}

.error_form .error_form__line {
  display: block;
  margin: 0;
  width: 100%;
  opacity: 0;
}

.error_form.active .error_form__line {
  opacity: 1;
}

.grecaptcha-badge {
  opacity: 0;
  pointer-events: none;
}

.audio-btn-container {
  position: absolute !important;
  right: 0;
  bottom: 70vh;
  color: black;
  width: fit-content;
  height: fit-content;
  width: 2em;
  height: 2em;
  display: flex;
  align-items: center;
}

.audio-btn {
  position: relative;
  width: 1.6em;
  height: 1.6em;
  color: #fff;
  padding: 0 !important;
  pointer-events: all !important;
  cursor: pointer;
  transform: skewX(-15deg) translateZ(0);
  background: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 150ms cubic-bezier(0.165, 0.84, 0.44, 1),
    filter 400ms cubic-bezier(0.165, 0.84, 0.44, 1) !important;
  /* easeOutQuart */

  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  /* easeOutQuart */
}

.audio-btn.hidden {
  pointer-events: none !important;
}

.audio-btn:hover {
  filter: invert(1);
}

.audio-btn .icon-wrap {
  border-radius: 0.4vh;
  width: 1.6em !important;
  height: 1.6em;
  margin: 0 !important;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
  /* easeInOutQuart */

  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  /* easeInOutQuart */
  transition-delay: 700ms;
}

.audio-btn.hidden .icon-wrap {
  width: 0 !important;
  height: 0;
}

.audio-btn .icon {
  width: 1.6em;
  height: 1.6em;
  transform: skewX(15deg);
  filter: invert(1);
  position: absolute;
  transition: all 200ms cubic-bezier(0.77, 0, 0.175, 1);
  /* easeInOutQuart */

  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  /* easeInOutQuart */
}

.audio-btn .icon img {
  width: 100% !important;
  height: 100% !important;
  padding: 0.3em;
  box-sizing: border-box;
}

@media (max-aspect-ratio: 1/1) {
  .home {
    font-size: max(2.4vh, 3vw);
  }
}

@media (max-width: 768px) {
  .home .logo>div {
    width: 5.3vh;
    height: 5.3vh;
    padding: 2.8vh;
  }
}

@media(max-width:600px) {
  .App {
    height: 100dvh;
  }
}