.contact-container {
  background: #e6e6e6;
  position: absolute;
  left: 0;
  bottom: 0;
  overflow: hidden;

  transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1), mask-position 0s;
  /* easeInOutQuart */

  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  /* easeInOutQuart */

  width: 100vw;
  height: 0;
}

.contact-container.open {
  height: 75vh;
}

.contact {
  background: #000;
  color: #e6e6e6;
  width: 100vw;
  height: 100vh;
  mask-repeat: no-repeat;
  mask-size: 100vw 600vh;
  mask-type: luminance;
  mask-position: 0 -400vh;

  transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1), mask-position 0s;
  /* easeInOutQuart */

  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  /* easeInOutQuart */

  top: -100vh;
  position: absolute;
}

.contact>div {
  height: 75vh;
  width: 100vw;
  position: absolute;
  bottom: 0;
  left: 0;
}

.contact .content {
  margin: 0 min(8vw, 20vh);
  display: grid;
  grid-template-columns: 35vw 1fr;
  transform: translateY(50%);
  transition: all 1s cubic-bezier(0.65, 0, 0.35, 1);
  height: 100%;
}

.contact.open {
  top: -25vh;
}

.contact.open .content {
  transform: translateY(0);
}

.contact .content> :first-child {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contact .content> :nth-child(2) {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.contact .content> :nth-child(2)> :first-child {
  margin-top: 11vh;
  font-size: 3vh;
}

.contact .content> :nth-child(2)>div {
  display: flex;
  gap: 1em;
  font-size: 2vh;
}

.contact .content> :nth-child(2) input,
.contact .content> :nth-child(2) textarea {
  width: 100%;
  height: 1.5em;
  resize: none;
  background: #000;
  color: #e6e6e6;
  border: none;
  border-bottom: solid 1px #636363;
  transition: all 400ms cubic-bezier(0.65, 0, 0.35, 1);
  font-size: 1.8vh;
}

.contact .content> :nth-child(2) input:hover,
.contact .content> :nth-child(2) textarea:hover {
  border-bottom: solid 1px #8a8a8a;
}

.contact .content> :nth-child(2) input:focus,
.contact .content> :nth-child(2) textarea:focus {
  border-bottom: solid 1px #e6e6e6;
}

.contact .content> :nth-child(2) textarea {
  height: 100%;
}

.contact .content> :nth-child(2)> :last-child> :first-child {
  height: 5vh;
  transform: skewX(-15deg);
  width: fit-content;
  background-color: #e6e6e6;
  border: none;
  color: #000;
  border-radius: 0.4vh;
  padding: 0.1em 0.5em;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.contact .content> :nth-child(2)> :last-child input {
  transform: skewX(15deg);
}

/* .contact .content> :nth-child(2)> :nth-child(4) {
    height: -webkit-fill-available;
} */

.contact .content .send {
  gap: 3.5em !important;
  position: absolute;
  bottom: 0;
  height: 5vh;
  width: 7em;
  margin-bottom: min(8vw, 20vh);
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  pointer-events: none;
}

.contact .content .send>div {
  transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1), mask-position 0s;
  /* easeInOutQuart */

  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  /* easeInOutQuart */
  transition-delay: 1300ms;
  width: 5.5em !important;
}

.contact .content .send>div.hidden {
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
}

.contact .contact-info {
  display: flex;
  flex-direction: column;
  margin-bottom: 11vh;
  gap: 1em;
  font-size: 1.8vh;
}

.contact .social-media {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 11vh;
}

.contact .social-media a {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.contact .social-media>div {
  width: 5vh;
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact .social-media>div>div {
  width: 3vh;
  height: 3vh;
  background: #e6e6e6;
  transform: skewX(-15deg);
  border-radius: 0.4vh;
  padding: 1vh;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1), mask-position 0s;
  /* easeInOutQuart */

  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  /* easeInOutQuart */
}

.contact .social-media>div>div.hidden {
  width: 0;
  height: 0;
  padding: 0;
}

.contact a {
  color: #e6e6e6;
  text-decoration: none;
}

.contact .social-media a img {
  width: 3vh;
  height: 3vh;
  object-fit: contain;
  transform: skewX(15deg);
}

.contact .button {
  background: none !important;
  color: #000 !important;
  border: none !important;
  cursor: pointer;
  font-size: 2.5vh !important;
  display: flex;
  justify-content: center;
}

.contact .content> :nth-child(2) .field-name {
  width: 12em;
  color: #b4b4b4;
}

.contact .btn-wrap {
  background: none;
  position: relative;
  height: 2em;
  display: flex;
  justify-content: center;
  font-size: max(21px, 3.3vh) !important;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  height: 5vh;
  width: 6em;
  margin-bottom: min(8vw, 20vh);
  flex-direction: row !important;
}

.contact .contact-btn {
  pointer-events: all;
  padding: 0;
  background: none;
  color: #000;
  width: -moz-fit-content;
  width: fit-content;
  /* transition: all 1s cubic-bezier(0.65, 0, 0.35, 1); */
  transition: all 900ms cubic-bezier(0.77, 0, 0.175, 1), color 0s, background 0s;
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1), color 0s,
    background 0s;
  transition-delay: 100ms;
  width: 6em;
  height: 1.6em;
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  margin: 0;
  position: absolute;
  transform: translateZ(0);
}

.contact .contact-btn.loading {
  width: 0;
  height: 0;
}

.contact .contact-btn.back {
  color: #fff;
  transform: skewX(-15deg);
}

.contact .contact-btn:before {
  content: '';
  background: #e6e6e6;
  /* transition: all 1s cubic-bezier(0.65, 0, 0.35, 1); */
  transition: all 900ms cubic-bezier(0.77, 0, 0.175, 1);
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  transition-delay: 100ms;
  width: 90%;
  height: 100%;
  position: absolute;
  display: block;
  border-radius: 0.4vh;
}

.contact .contact-btn.back:before {
  background: #000;
  width: calc(90% - 2px);
  height: calc(100% - 2px);
}

.contact .contact-btn>div {
  background: none;
  width: 90%;
  height: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  /* transition: all 1s cubic-bezier(0.65, 0, 0.35, 1); */
  transition: all 900ms cubic-bezier(0.77, 0, 0.175, 1);
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  position: relative;
  overflow: hidden;
  justify-content: center;
}

.contact .contact-btn.back>div {
  width: 90%;
  justify-content: center;
}

.contact .contact-btn>div>div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* transition: all 1s cubic-bezier(0.65, 0, 0.35, 1); */
  transition: all 900ms cubic-bezier(0.77, 0, 0.175, 1);
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  transform: skewX(15deg);
}

.contact .contact-btn.back>div>div {
  left: -2em;
}

.contact .btn-wrap:hover .contact-btn>div>div {
  left: 1em;
}

.contact .btn-wrap:hover .contact-btn.back>div>div {
  left: 0;
}

.contact .btn-hover-wrap-container {
  width: 6em;
  margin: 0;
  display: flex;
  /* transition: all 1s cubic-bezier(0.65, 0, 0.35, 1); */
  transition: all 900ms cubic-bezier(0.77, 0, 0.175, 1);
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}

.contact .btn-hover-wrap-container.loading {
  width: 0;
}

.contact .btn-hover-wrap {
  width: 6em;
  height: 1.6em;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  justify-self: flex-start;
  overflow: hidden;
  position: relative;
  /* transition: all 1s cubic-bezier(0.65, 0, 0.35, 1); */
  transition: all 900ms cubic-bezier(0.77, 0, 0.175, 1);
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  transform: skewX(-15deg);
}

.contact .contact-btn-container.hidden .contact-btn {
  pointer-events: none;
}

.contact .btn-wrap:hover .btn-hover-wrap {
  width: 0;
}

@media (max-aspect-ratio: 1/1) {
  .contact .content {
    grid-template-columns: 1fr;
  }

  .contact .content> :first-child {
    grid-row-start: 2;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
  }

  .contact .social-media {
    margin: 0;
    gap: 0.5rem;
  }

  .contact .contact-info {
    margin: 0;
    font-size: 1.5vh;
    width: max-content;
  }

  .contact .content> :nth-child(2) {
    gap: 1.5em;
  }

  .contact .content> :nth-child(2)> :first-child {
    margin-top: min(8vw, 20vh);
    font-size: 2.7vh;
  }

  .contact .content> :nth-child(2)>div:not(.send) {
    flex-direction: column;
    gap: 0.2em;
  }

  .contact .content> :nth-child(2) .field-name {
    font-size: 1.6vh;
  }

  .contact .content> :nth-child(2) input,
  .contact .content> :nth-child(2) textarea {
    font-size: 2vh;
  }

  .contact .content .send {
    gap: 1.8em !important;
  }

  .contact .btn-wrap {
    font-size: max(2.4vh, 3vw) !important;
  }

  .contact .btn-wrap {
    position: relative;
    bottom: auto;
  }
}

@media (max-width: 768px) {
  .contact .btn-wrap {
    position: relative;
    bottom: auto;
  }

  .contact .button {
    font-size: 2vh !important;
  }

  .contact .social-media>div {
    width: 4vh;
    height: 4vh;
  }

  .contact .social-media>div>div {
    width: 4vh;
    height: 3vh;
    padding: 0.5vh;
  }

  .contact .social-media a img {
    width: 1.5vh;
    height: 1.5vh;
  }

  .contact .content> :nth-child(2)> :last-child> :first-child {
    height: 4vh;
  }

  .contact a {
    position: relative;
  }
}